exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bad-js": () => import("./../../../src/pages/bad.js" /* webpackChunkName: "component---src-pages-bad-js" */),
  "component---src-pages-badstuff-js": () => import("./../../../src/pages/badstuff.js" /* webpackChunkName: "component---src-pages-badstuff-js" */),
  "component---src-pages-bottest-js": () => import("./../../../src/pages/bottest.js" /* webpackChunkName: "component---src-pages-bottest-js" */),
  "component---src-pages-cmdi-js": () => import("./../../../src/pages/cmdi.js" /* webpackChunkName: "component---src-pages-cmdi-js" */),
  "component---src-pages-good-js": () => import("./../../../src/pages/good.js" /* webpackChunkName: "component---src-pages-good-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-l-4-j-2-js": () => import("./../../../src/pages/l4j2.js" /* webpackChunkName: "component---src-pages-l-4-j-2-js" */),
  "component---src-pages-l-4-j-js": () => import("./../../../src/pages/l4j.js" /* webpackChunkName: "component---src-pages-l-4-j-js" */),
  "component---src-pages-phpi-js": () => import("./../../../src/pages/phpi.js" /* webpackChunkName: "component---src-pages-phpi-js" */),
  "component---src-pages-struts-js": () => import("./../../../src/pages/struts.js" /* webpackChunkName: "component---src-pages-struts-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-templates-commerce-js": () => import("./../../../src/templates/commerce.js" /* webpackChunkName: "component---src-templates-commerce-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

